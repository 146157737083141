/* src/components/Welcome.css */
.welcome {
  text-align: center;
}

.welcome h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.welcome p {
  font-size: 1.2em;
  color: #555;
}
