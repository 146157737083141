body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.active {
  background: rgb(0, 80, 115);
  color: #ffffff !important;
  width: 100%;
}

th {
  background-color: #005073 !important;
  color: #ffffff !important;
  padding: 10px 20px !important;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

main {
  height: 100vh;
  overflow: scroll;
}

.button {
  padding: 10px 18px;
  color: #ffffff;
  background: rgb(0, 80, 115);
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  min-width: 100px;
}

.button:hover {
  background: rgb(0, 90, 135);
}

.outlineButton,
.outlineButton:hover {
  color: rgb(0, 80, 115);
  border: 1px solid rgb(0, 80, 115);
  background: transparent;
}

.overflowTable {
  max-width: 100%;
  overflow: scroll;
}

label {
  font-size: 0.85rem;
}

input::placeholder {
  font-size: 0.85rem;
}
