/* src/components/UploadFiles/UploadFiles.css */
.upload-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* padding: 20px; */
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  /* margin-bottom: 20px; */
}

.upload-box {
  border-bottom: 1px solid #ccc;
  margin: 20px 20px 0px 20px;
  padding-bottom: 20px;
  /* border-radius: 5px; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.upload-box h2 {
  margin: 0;
  margin-bottom: 10px;
}

.upload-box h3 {
  margin: 0;
  margin-bottom: 20px;
}

/* .upload-box input[type="file"] {
  display: block;
} */

.fileInputContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 10px;
  left: 8px;
  font-size: 17px;
  color: #b8b8b8;
}
.fileInputWrap {
  position: relative;
}
.uploadButton {
  display: inline-block;
  padding: 12px 18px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  /* font-weight: bold; */
  color: gray;
  border: 1px solid gray;
}

.fileNameWarper {
  border: 1px solid gray;
  padding: 12px 18px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 14px;
  color: gray;
  cursor: not-allowed;
}
