/* src/App.css */
.App.authenticated {
  display: flex;
}

.content {
  flex-grow: 1;
  padding: 20px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background: white;
  overflow: auto;
  max-height: 90%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  z-index: 1001;
  display: block; /* Ensure the modal is displayed */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modal-content {
  text-align: center;
}

.modal-image {
  width: 100%;
  height: 100%;
}

button {
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.leftBarDiv {
  padding-left:0px;
}