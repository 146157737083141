table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  /* border: 1px solid black; */
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  /* background-color: #f2f2f2; */
}
