/* src/components/Sidebar.css */
.sidebar {
  min-height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  background-color: #1c1b1be6;
}

.nav-item:hover {
  background-color: rgb(0, 80, 115);
  color: #ffffff;
}

.nav-link {
  color: #ffffff;
}

.nav-link:hover {
  color: #ffffff;
  background: rgb(0, 80, 115);
}

.active {
  background: rgb(0, 80, 115);
  color: #ffffff;
  width: 100%;
}
