.multiple-sheets-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.sheet-list {
  margin: 20px 0;
}

.sheet-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sheet-item input {
  margin-right: 10px;
}

.loading-message {
  text-align: center;
  font-size: 16px;
  margin: 20px 0;
  margin-top: 10% !important;
}

.button-container {
  display: flex;
  justify-content: center;
}
