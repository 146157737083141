/* src/components/Login.css */
.login_page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.login_container {
  /* background: #fff; */
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input_text {
  font-size: 14px;
  color: #333;
  margin-right: 10px;
}

.input_element {
  /* width: 400px; */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login_button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.login_button:hover {
  background-color: #0056b3;
}

#error_msg {
  color: red;
  font-size: 12px;
}
