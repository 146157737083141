.files-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.file-box {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  max-width: 275px;
}

.file-box p {
  margin-bottom: 8px;
  font-weight: bold;
}

/* .file-box button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.file-box button:hover {
  background-color: #0056b3;
} */
