/* src/pages/ShowListOfTemplates.css */
.templates {
  padding: 20px;
}

.template {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.templateImages {
  width: 100%;
  height: calc(100vh / 2.8);
  cursor: pointer;
}

.template p {
  margin-top: 10px;
  font-size: 1.1em;
  color: #333;
}

.slickPrev:before,
.slickNext:before {
  color: black;
}

.singleImage {
  width: 100%;
  cursor: pointer;
  height: calc(100vh / 2.65);
}

.imageDiv {
  max-height: 90%;
  overflow: scroll;
}

.modalContent {
  position: relative;
  padding: 20px;
  text-align: center;
  /* height: 100%; */
}

.closeButton {
  background: none;
  color: #007bff;
  cursor: pointer;
  font-size: 1.5rem;
  position: absolute;
  right: 4px;
  top: -15px;
  z-index: 1001;
  border: 1px solid;
  border-radius: 17px;
  font-size: 12px;
  margin-top: 25px;
  margin-right: 20px;
}

.imageDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  max-height: 700px;
}

.modalImage,
.sliderImages {
  width: 100%;
  height: auto;
  min-height: calc(100vh / 1.6);
}

.button {
  padding: 10px 18px;
  color: #ffffff;
  background: rgb(0, 80, 115);
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  min-width: 100px;
}

.button:hover {
  background: rgb(0, 90, 135);
}

.outlineButton,
.outlineButton:hover {
  color: rgb(0, 80, 115);
  border: 1px solid rgb(0, 80, 115);
  background: transparent;
}
