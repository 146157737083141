.key-stats-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.key-stats-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.loading-message {
  text-align: center;
  font-size: 16px;
  margin: 20px 0;
}

.button {
  align-self: flex-end;
}
